<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {
  usersMethods,
  usersGetters,
  globalGetters
} from "@/state/helpers";

export default {
  components: {
    Layout,
    PageHeader
  },
  created(){
  },
  beforeRouteEnter(to, from, next) {
    next(vm => { 
      let users = vm.$store.state.users.users;
      if(users.length === 0){
        vm.$store.dispatch('users/setUsers',{page:1,perPage:20,filter:''});
      }
    });
  },
  computed:{
    ...usersGetters,
    ...globalGetters
  },
  data() {
    return {
      title: "Users",
      selected: 'first_name',
      options: [
        { item: 'first_name', name: 'Filter By First Name' },
        { item: 'last_name', name: 'Filter By Last Name' },
        { item: 'email', name: 'Filter By Email'},
        { item: 'user_id', name: 'Filter By User ID' }
      ],
      perPage:20,
      filter:'',
      page:1,
      pageOptions:[20,25,50],
      fields: [
        { key: "ID", sortable: true, label: "User ID" },
        { key: "first_name", label: "First Name" },
        { key: "last_name",label: "Last Name"},
        { key: "user_email", sortable: true, label: "Patient Email" },
        { key: "phone_number", sortable: true, label: "Phone Number" },
        { key: "postcode", sortable: true, label: "Post Code" },
        { key: "user_type", sortable: true, label: "User Type" },
        { key: "user_registered",label: "Registered Date" },
        { key: "orders_count",label: "Orders Count" },
        { key: "action" }
      ],
      submitted: false,
      showmodal: false,
      filterEnable: true,
      noData:[{
        user_email : 'No Users Found.'
      }],
    };
  },

  methods: {
    ...usersMethods,
    handlePagination(page){
        let perPage = this.perPage,
        searchBy = this.selected,
        filter = this.filter;
      this.setUsers({page,perPage,filter,searchBy});
    },
    handlePerPage(){
      let perPage = this.perPage,
          searchBy = this.selected,
          filter = '';
          this.currentPage = 1;
      this.setUsers({page:1,perPage,filter,searchBy});
    },
    handleSearch(){
      let filter = this.filter,
      searchBy = this.selected;
      if(filter.length >= 3){
        this.setUsers({page:1,perPage:20,filter,searchBy});
      }else{
        this.setUsers({page:1,perPage:20,filter:'',searchBy});
      }
    },
    hideModal() {
      this.submitted = false;
      this.showmodal = false;
      this.contacts = {};
    },
    resetSearchFilter(){
      let perPage = this.perPage;
      this.filter = '';
      this.setUsers({ page:1, perPage, filter: '', searchBy: '' });
    },
    handleFilterSearch(){
      let perPage = this.perPage;
      let filter = this.filter,
      searchBy = this.selected;
      this.setUsers({ page:1, perPage, filter, searchBy});
    },
    handleFilterValue(){
      if(this.filter.length >= 2){
        this.filterEnable = false
      }else{
        this.filterEnable = true
      }
    }

  }
};
</script>

<template>
  <Layout>
    <b-row>
      <b-col>
        <PageHeader :title="title" />
      </b-col>
    </b-row>
    <div class="row">
      <div class="col-lg-12">
         <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">All Users</a>
                </template>
                 
                <div class="row mt-4 mb-2">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select v-model="perPage" @change="handleUserPerPage('all')" size="sm"
                          :options="pageOptions"></b-form-select>&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search  -->
                  <div class="col-sm-12 col-md-4 offset-lg-2">
                    <div id="tickets-table_filter"
                      class="row no-gutters align-items-center dataTables_filter text-md-right">
                      <b-col>
                        <b-button variant="outline-primary" class="py-1" v-b-toggle.sidebar-right><i class="mdi mdi-filter"></i> Filters</b-button>
                      </b-col>
                    </div>
                  </div>
                  <!-- End search  -->
                </div>
                <div class="table-responsive">
                  <!-- {{ row }} -->
                  <b-table class="table-centered" :busy="loader" :items="getUsers" :fields="fields" responsive="sm"
                    :per-page="perPage" :current-page="1" show-empty>
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Loading...</strong>
                      </div>
                    </template>
                    <template #emptyfiltered>
                    </template>
                    <template v-slot:cell(ID)="row">
                      <div>#{{ row.value }}</div>
                    </template>
                    <template v-slot:cell(payment_status)="row">
                      <div class="badge font-size-12" :class="{
                        'badge-soft-danger': `${row.value}` === 'Chargeback',
                        'badge-soft-success': `${row.value}` === 'successful',
                        'badge-soft-warning': `${row.value}` === 'Unpaid'
                      }">{{ row.value }}</div>
                    </template>
                    <template v-slot:cell(age_checked)="row">
                      <div class="badge font-size-12" :class="{
                        'badge-soft-danger': `${row.value}` === 'unverified',
                        'badge-soft-success': `${row.value}` === 'verified',
                        'badge-soft-warning': `${row.value}` === 'not_attempt'
                      }">{{ row.value }}</div>
                    </template>

                    <template v-slot:cell(orders_count)="row">
                      <!-- :to=" (row.value != 0) ? `/orders/${row.item.ID}` : '#' "  -->
                      <router-link :to="(row.value != 0) ? { path: `/user-orders/${row.item.ID}`} : '#'" class="mr-3 text-success" v-b-tooltip.hover title="View" >                      
                        <b-badge class="py-1 px-2" variant="info">{{row.value}}</b-badge>
                      </router-link>
                    </template>

                    <template v-slot:cell(action)="row">
                      <router-link :to="'/users/'+row.item.ID" class="mr-3 text-success" v-b-tooltip.hover title="View" >                      
                        <i class="mdi mdi-eye font-size-18"></i>
                      </router-link>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination  -->
                        <b-pagination v-model="currentPage" :total-rows="totalUsers" :per-page="20" @input="handlePagination($event)">
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div> 
      </div>
    </div>

    <b-sidebar id="sidebar-right" right shadow bg-variant="light" backdrop backdrop-variant="transparent">
      <b-form class="px-3 py-4" @submit.prevent="handleFilterSearch()">
        <h5>Filtering & Sorting</h5>
        <hr class="mt-3 mb-4"/>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="order_id">Order ID</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected"  class="card-title" value="email">Email</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="first_name">First Name</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="last_name">Last Name</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="phone_number">Phone Number</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="postcode">Post Code</b-form-radio>
        </b-form-group>
        <b-form-group>
           <b-form-radio v-model="selected" class="card-title" value="bmi">BMI</b-form-radio>
        </b-form-group>

      <b-form-group id="input-group-2" class="card-title mb-3" 
      :label="selected == 'first_name' ? 'Enter First Name:' :
        selected == 'order_id' ? 'Enter Order ID:' : 
        selected == 'email' ?  'Enter Email:' : 
        selected == 'last_name' ?  'Enter Last Name:' :
        selected == 'phone_number' ?  'Enter Phone Number:' :
        selected == 'postcode' ?  'Enter Post Code:' :
        selected == 'bmi' ?  'Enter BMI:' :
        'Enter Phone Number:'" label-for="input-2">
        <b-form-input
          id="input-2"
          :type="selected == 'email' ? 'email' : 'search'"
          v-model="filter"
          @input="handleFilterValue"
          required
        ></b-form-input>
      </b-form-group>

      <b-button variant="success" class="mr-2" type="submit" :disabled="filterEnable">
          <i class="ri-check-line align-middle mr-2"></i> Submit
      </b-button>
      <b-button variant="danger" type="reset" @click="resetSearchFilter" :disabled="filterEnable">
          <i class="ri-close-line align-middle mr-2"></i> Reset
      </b-button>
      </b-form>
    </b-sidebar>

  </Layout>
</template>